<template>
    <div class="main">
        <van-nav-bar
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"
        title="提现记录"
        />
        <div class="body">
            <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                <van-list
                    v-model="loading"
                    :finished="finished"
                    finished-text="没有更多了"
                    @load="onLoad"
                    :immediate-check="false"
                >
                    <div v-for="(item, index) in list" :key="index" class="item">
                        <div v-if="item.guild_id == 0" class="amount">{{ formatRmb(item.reality_rmb) }}</div>
                        <div v-if="item.guild_id>0" class="amount">{{ item.amount }}钻石</div>
                        <div class="statusAndTime">
                            <div v-if="item.status==0" class="status" style="color: #FDC340">审核中</div>
                            <div v-if="item.status==1" class="status">成功</div>
                            <div v-if="item.status==2" class="status" style="color: #FDC340">驳回</div>
                            <div v-if="item.status>=3" class="status" style="color: #FDC340">支付中</div>
                            <div class="time">{{ item.create_time }}</div>
                        </div>
                    </div>
                </van-list>
            </van-pull-refresh>
        </div>
    </div>
</template>

<script>
import { doRequest } from '@/utils/request'

export default {
    data() {
        return {
            page: 1,
            page_size: 20,

            refreshing: false,

            loading: false,
            finished: false,

            list: [],
        }
    },
    methods: {
        onClickLeft() {
            this.$router.go(-1)
        },
        formatRmb(rmb) {
            return '￥ ' + parseFloat(rmb / 100.0).toFixed(2)
        },
        onRefresh() {
            this.page = 1
            this.list = []
            this.initData()
        },
        onLoad() {
            this.page++
            this.initData()
        },
        initData() {
            doRequest('/withdraw/record', {
                page: this.page,
                page_size: this.page_size,
                currency: parseInt(this.$route.query.currency, 0),
            }, this.$route.query).then(res => {
                this.list = this.list.concat(res.list)
                this.loading = false
                this.refreshing = false
                if (res.list.length < this.page_size) {
                    this.finished = true
                }
            })
        },
    },
    mounted () {
        this.initData()
    },
}
</script>

<style scoped>
.main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 375px;
}

.body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 375px;
}

.item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 351px;
    height: 80.5px;
    border-bottom: 1px solid #DDDDDD;
}

.item .amount {
    height: 16px;
    line-height: 16px;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
}

.item .statusAndTime {
    height: 42px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
}

.item .statusAndTime .status {
    height: 13px;
    line-height: 13px;    
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
}

.item .statusAndTime .time {
    height: 11px;
    line-height: 11px;    
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
}
</style>